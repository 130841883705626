<template>
    <div>
        <ul class="nav flex">
            <li @click="navTab(item.id)" :class="navId == item.id ? 'active' : ''" v-for="item in navList"
                :key="item.id">{{ item.title }}
                <span class="status_num" v-if="item[num]">{{ item[num] }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getOrderNum } from '@/util/orderNum'
export default {
    name: '',
    created() { },
    props: {
        navId: Number,
        num: String,
    },
    computed: {
        ...mapState(['pubNav', 'mediaOrder'])
    },
    mounted() {
        this.getMediaNav()
    },
    data() {
        return {
            navList: [],

        }
    },
    methods: {
        navTab(id) {
            this.$emit('navTab', id)
            this.getMediaNav()
        },
        getMediaNav() {
            this.curlGet('/api/medium_category/list').then(res => {
                if (res.data.code) {
                    console.log(res.data, 'res');
                    this.navList = res.data.data
                    // this.$store.state.UserMediaNav = res.data.data
                    // this.$store.state.UserTypeItem = res.data.data[0]
                }
            })
        },
    },
}
</script>

<style lang=scss scoped>
.nav {
    // width: 60%;
    border-bottom: 0.1rem solid #e6e9f4;
    margin-bottom: 1rem;
    align-items: center;
    height: 5rem;

    li {
        // height: 5rem;
        // line-height: 5rem;
        color: #333;
        font-size: 1.22rem;
        padding: 0 1rem;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        &:first-child {
            padding-left: 0;
        }

        // &:last-child {
        //     padding-right: 0;
        // }
    }

    .active {
        color: #108CDD !important;
        font-weight: 700;

        &:hover {
            color: #108CDD !important;
        }
    }
}
</style>