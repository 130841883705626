<template>
    <div>
        <p v-if="limitNum" class="limit_time">[限时订单]</p>
    </div>
</template>

<script>
export default {
    props: {
        limitNum: Number,
    },
    name: '',
    created() { },
    mounted() { },
    data() {
        return {}
    },
    methods: {},
}
</script>

<style lang=scss scoped>
.limit_time {
    color: #ff0000;
}
</style>