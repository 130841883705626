<template>
    <div>
        <div class="order_search flex">
            <img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
            <el-input class="order_search_inp" v-model="name" placeholder="请输入媒介名称" clearable @clear="searchMedium"
                @keyup.enter.native="searchMedium"></el-input>
            <button class="search_but" @click="searchMedium">搜索</button>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    created() { },
    mounted() { },
    props: {
        medium_name: {
            type: [String],
            default:""
        }

    },
    data(){
        return{
            name:''
        }
    },
    methods: {
        searchMedium(){
            this.$emit("searchMedium",this.name)
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/MediaOrder';
</style>